import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

Sentry.init({
  dsn: "https://c385a6726cf917fd9655c525b46aad94@o4507588851335168.ingest.us.sentry.io/4508161389756416",
  tracesSampleRate: 1,
  enabled: process.env.NODE_ENV !== "development",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  LicenseManager.setLicenseKey(window.env!.AG_GRID_ENTERPRISE_KEY);
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
